/**
 * Pattern Explorer a priori utilities
 * 
 * General-purpose helper functions.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2022.
 */

import { HasId } from "./types";

/** 
 * Returns a random integer x such that 0 <= x < max.
 */ 
export const getRandomInt = (max: number): number =>
  Math.floor(Math.random() * max);


/**
 * Returns an object in an array with the specified id. 
 */
export const getItemById = <T extends HasId>(items: Array<T>, id: any): T =>
  items.find(item => (item.id === id));


/**
 * Log to console on development and testing.
 */
export const devLog = (
  ['test', 'development'].includes(process.env.APP_ENV) ?
  console.log :
  (msg: string) => {}
);
