/**
 * Pattern Explorer REST API client.
 * 
 * Provides a convenient interface for making API requests to the
 * Polly and Pattern Explorer backends while handling authentication.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2022-2023.
 */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getAuthenticationToken } from './token';


/**
 * Make a private API request.
 * 
 * Provides an interface similar to Axios while handling token
 * authentication for private endpoints. When an HTTP 401 response
 * is received from the server, this function will try to refresh
 * the access token and perform the request a second time.
 */
const reqPriv = async (url: string, config: AxiosRequestConfig) => {
  const request = {url, ...config};
  let response: AxiosResponse | null = null;
  if (!('headers' in request)) {
    request.headers = {};
  }

  const token = await getAuthenticationToken();
  request.headers.Authorization = `Bearer ${token}`;

  try {
    response = await axios(request);
  } catch (error) {

    if (error?.response?.status === 401) {
      const newToken = await getAuthenticationToken()
      if (newToken !== token) {
        request.headers.Authorization = `Bearer ${newToken}`;
        response = await axios(request);
      }
    }

    if (!response) {
      throw error;
    }
  }
    
  return response;  
};


/**
 * Check if an error is a cancellation error.
 * 
 * Returns true if the argument was thrown as a result of calling
 * AbortController.abort().
 * 
 * The default fetch function raises a different error than axios
 * when a request is aborted through an AbortSignal. Fetch raises
 * a DOMException named AbortError, whereas axios raises
 * an error named CanceledError with a __CANCEL__ attribute. Axios
 * provides the axios.isCancel helper function to check for this
 * type of error.
 * 
 * High level pattern explorer code should not have to be aware
 * of these axios specifics. Instead, this constant can be imported
 * and may be reassigned in the future if we were to change our
 * REST implementation from using axios to something else.
 */
export const isAbortError = axios.isCancel;


/**
 * REST API Client class
 */
export class Client {
  url: string;

  constructor(host: string, path: string) {
    this.url = host + path;
  }

  pub(path: string, config: AxiosRequestConfig = {}) {
    return axios.get(this.url + path, config);
  }

  get(path: string, config: AxiosRequestConfig = {}) {
    return reqPriv(this.url + path, {method: 'get', ...config});
  }

  async getData(path: string, config: AxiosRequestConfig = {}) {
    return (await this.get(path, config)).data;
  }

  /**
   * Read all pages from a paginated list endpoint and combine the data.
   */
  async depaginate(path: string, config: AxiosRequestConfig = {}) {
    const conf = {method: 'get', ...config};
    const data = [];
    let url = this.url + path;
    while (url) {
      const { pagination, result } = (await reqPriv(url, conf)).data;
      data.push(...result);
      url = pagination.next;
    }
    return data;
  }

  patch(path: string, data: any, config: AxiosRequestConfig = {}) {
    return reqPriv(this.url + path, {method: 'patch', data, ...config});
  }

  post(path: string, data: any, config: AxiosRequestConfig = {}) {
    return reqPriv(this.url + path, {method: 'post', data, ...config});
  }

  put(path: string, data: any, config: AxiosRequestConfig = {}) {
    return reqPriv(this.url + path, {method: 'put', data, ...config});
  }

}

export const explorerApi = new Client(process.env.REACT_APP_EXPLORER_BE_HOST, '/api/');
export const pollyApi = new Client(process.env.REACT_APP_POLLY_BE_HOST, '/');
