/**
 * Pattern Explorer authentication configuration.
 * 
 * Adapted from Label A by Sander Voerman, 2022-2024.
 */


export const authConfig = {
  TOKEN_STORAGE_KEY: 'auth_refresh_token',
  REFRESH_TOKEN_INTERVAL: 300, /* 5 minutes = 300 seconds */
  AUTH_URL: process.env.REACT_APP_POLLY_BE_HOST + '/auth', 
  FEDERATION_LOGOUT_URL: process.env.REACT_APP_EXPLORER_FE_HOST + '/',
  KEYCLOAK_PROVIDER: 'keycloak',
  KEYCLOAK_REDIRECT_URI: process.env.REACT_APP_EXPLORER_FE_HOST + '/keycloak',
  KEYCLOAK_CLIENT_ID: 'polly_iq',
  KEYCLOAK_REQUEST_LOGOUT: process.env.REACT_APP_KEYCLOAK_DOMAIN_URL + '/realms/parnassia/protocol/openid-connect/logout',
};
