/**
 * Pattern Explorer a priori types
 * 
 * General purpose type definitions.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2022.
 */


/**
 * Any object that has an id field.
 */
export interface HasId {
  id: any;
}


/**
 * Which side of a node an edge should connect to.
 * 
 * This type replicates the Position enum from React Flow except that
 * we can use it without having to depend on importing either RF9 or RF10.
 */
export enum EdgePosition {
  Top,
  Right,
  Left,
  Bottom,
}
