/**
 * Logout redirect
 * 
 * Adapted from Label A by Sander Voerman, 2022-2024.
 */

import { removeAuthenticationTokens } from './useAuthentication';
import { getAuthenticationConfig } from './useAuthentication/services';

export const logoutKeyCloakRedirect = (): void => {
  const {
    FEDERATION_LOGOUT_URL: returnUrl,
    KEYCLOAK_REQUEST_LOGOUT: keyCloakUrl,
    KEYCLOAK_CLIENT_ID: clientId,
  } = getAuthenticationConfig();

  removeAuthenticationTokens();
  window.location.replace(`${keyCloakUrl}?client_id=${clientId}&post_logout_redirect_uri=${returnUrl}`);
};
