/**
 * KeyCloak callback component
 * 
 * Adapted from Label A by Sander Voerman, 2023.
 */

import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFederatedAuthenticationActions } from './useAuthentication';

const KeyCloakCallback: React.FC = () => {
  const navigate = useNavigate();
  const { requestFederatedKeyCloakLogin } = useFederatedAuthenticationActions();

  const onRequestFederatedLogin = async () => {
    await requestFederatedKeyCloakLogin()
      .then(() => {
        navigate('/', {replace: true});
      })
      .catch(() => {
        navigate('/loginfailed', {replace: true});
      });
  };

  const didInitialise = useRef(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {

    /*
      Prevent authentication from being initiated twice under
      React 18 strict mode
    */
    if (didInitialise.current) {
      return;
    }
    didInitialise.current = true;
  
    onRequestFederatedLogin();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <div>Inloggen...</div>
  );
};

export default KeyCloakCallback;