/**
 * Pattern Explorer splash card
 * 
 * This module is loaded as part of the first webpack chunk.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2022.
 */

import React from 'react';


export const SplashContainer = ({children}) => (
  <div className='pe-splash-container'>
    {children}
  </div>
);


export const Splash = ({message}) => (
  <SplashContainer>
    <div className='pe-splash-card'>
      {message}
    </div>
  </SplashContainer>
);
