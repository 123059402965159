/**
 * Authentication context provider
 * 
 * Adapted from Label A by Sander Voerman, 2022-2024.
 */

import React, { ReactNode, useRef } from 'react';

import { getAuthenticationToken } from './token';
import { AuthenticationState, AuthenticationContextType } from './types';

export const AuthenticationContext = React.createContext<AuthenticationContextType | null>(null);

export const initialState: AuthenticationState = {
  authenticated: undefined,
  loading: false,
  error: false,
};

export const AuthenticationProvider = ({ children }: {
  children: ReactNode,
}) => {
  const [user, setUser] = React.useState(initialState);
  const dispatch = (userData: Partial<AuthenticationState>) => {
    const state = {
      ...user,
      ...userData,
    };

    setUser(state);
    return state;
  };
  const didInitialise = useRef(false);

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {

    /*
      Prevent authentication from being initiated twice under
      React 18 strict mode
    */
    if (didInitialise.current) {
      return;
    }
    didInitialise.current = true;

    const determineUser = async () => {
      dispatch({ loading: true });

      getAuthenticationToken()
        .then(() => {
          dispatch({
            authenticated: true,
            loading: false,
          });
        })
        .catch(() => {
          dispatch({
            authenticated: false,
            loading: false,
          });
        });
    };

    determineUser();
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <AuthenticationContext.Provider value={{ state: user, dispatch }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
