/**
 * Pattern Explorer DateTime module.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2022.
 */

export class DateTime {
  iso: string;

  constructor(iso: string) {
    this.iso = iso;
  }

  localDate() {
    return this.iso.slice(0, 10);
  }

  localTime() {
    return this.iso.slice(11, 19);
  }

  local() {
    return `${this.localDate()} ${this.localTime()}`;
  }
  
}