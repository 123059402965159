/**
 * KeyCloak federated actions
 * 
 * Adapted from Label A by Sander Voerman, 2022-2024.
 */

import qs from 'qs';
import axios from 'axios';

import { devLog } from '../../apriori';
import { logoutKeyCloakRedirect } from '../redirectToLogin';

import { useAuthenticationContext } from './hooks';
import { Auth, getAuthenticationConfig } from './services';
import { getRefreshToken, setAuthenticationTokens } from './token';
// import { pollyApi } from './client';
import { AuthenticationState } from './types';


export const useFederatedAuthenticationActions = () => {
  const { dispatch } = useAuthenticationContext();

  const requestKeyCloakLogin = () => (
    new Promise<AuthenticationState>((resolve, reject) => {
      dispatch({ loading: true });
      const { KEYCLOAK_REDIRECT_URI, KEYCLOAK_PROVIDER } = getAuthenticationConfig();

      Auth.get({ path: `/o/${KEYCLOAK_PROVIDER}/?redirect_uri=${KEYCLOAK_REDIRECT_URI}` })
        .then((response) => {
          const { authorization_url } = response;
          window.open(authorization_url, '_self');
        })
        .catch((error) => {
          dispatch({
            loading: false,
            error: true,
          });
          console.log('Failed to obtain login URL from Polly backend.');
          console.log(error);
          reject(error);
        });
      
    })
  );

  const requestFederatedKeyCloakLogin = () => (
    new Promise<AuthenticationState>((resolve, reject) => {
      const {
        KEYCLOAK_PROVIDER,

        // Why are we using this at this stage?? (see also comment below)
        KEYCLOAK_REDIRECT_URI: redirectUri,

      } = getAuthenticationConfig();
      const { code } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

      if (!code) {
        dispatch({ error: true });
        return reject('code is not present in the url.');
      }

      dispatch({ loading: true });

      const formData = new FormData();

      formData.append('code', code as string);
      
      // Why would the following be needed??
      // TODO: test if I can remove this
      formData.append('redirect_uri', redirectUri);

      Auth.post({
        path: `/o/${KEYCLOAK_PROVIDER}/`,
        body: formData,
      })
        .then((tokens) => {
          devLog(tokens);

          setAuthenticationTokens(tokens);
          const response = dispatch({
            authenticated: true,
            loading: false,
            error: false,
          });

          resolve(response);
        })
        .catch((error) => {
          dispatch({
            loading: false,
            error: true,
          });

          reject(error);
        });
    })
  );

  const requestLogout = () => {

    dispatch({ loading: true });

    /*
      Put refresh token on blacklist until it expires.

      Actually, it might be that this endpoint performs a full
      logout and instead of adding it here, we could reimplement
      our logout procedure to use the logout function from the
      hooks module, which keeps the react application running
      instead of performing the redirect roundtrip to and from
      an KeyCloak location in the browser.

      However, that also requires refactoring this frontend so
      that it manually resets all its state when a user logs out.
      Perhaps it would be better to wait for such refactoring
      when we know more about how authentication is going to change
      in Polly v3?
    */
    axios.post(
      `${process.env.REACT_APP_POLLY_BE_HOST}/auth/jwt/logout`,
      { refresh: getRefreshToken() }
    ).then(() => {

      devLog('Logging out...');
      logoutKeyCloakRedirect();

    }).catch(err => {
      console.log(err);
      throw err;
    });
    
  };

  return {
    requestKeyCloakLogin,
    requestFederatedKeyCloakLogin,
    requestLogout,
  };
};
