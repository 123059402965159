/**
 * Pattern Explorer app module.
 * 
 * Main application component.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2024.
 */
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { AuthenticationProvider } from "./auth/useAuthentication";
import KeyCloakCallback from "./auth/KeyCloakCallback";
import LazyLoader from "./LazyLoader";


const LoginFailed = () => (
  <>
    <div>Het inloggen is niet gelukt.</div>
    <div><a href="/">Terug</a></div>  
  </>
);


const App = () => (
  <AuthenticationProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/keycloak" element={<KeyCloakCallback />} />
        <Route path="/loginfailed" element={<LoginFailed />} />
        <Route path="/" element={<LazyLoader />} />
      </Routes>
    </BrowserRouter>
  </AuthenticationProvider>
);

export default App;
