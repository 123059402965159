/**
 * Pattern Explorer application configuration.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2022.
 */


/**
 * Practitioner manual on Sharepoint.
 */
export const EXPLAINER_URL = (
  'https://parnassiagroep.sharepoint.com/:w:/r/sites/com_patroonintake-' +
  'proeftuinen/Gedeelde%20documenten/Handleidingen%20en%20werkinstructies/' +
  'Handleiding%20Patroonverkenner%20en%20smartboard.docx?d=w40b0f055f9f34b' +
  'e5a8d0c11ae2f82fe4&csf=1&web=1&e=cA48v5'
);


/**
 * Building block module type in the Polly database.
 * 
 * There is only one building block module in Polly and it has a unique
 * type, so we can use the type to identify it.
 */
export const POLLY_BB_MODULE_TYPE = 'PATTERN_SELECT_BUILDING_BLOCK';


/**
 * PoLL module UUID in the Polly database.
 * 
 * The PoLL module in Polly is one of several modules of the SURVEY type,
 * so we need to use the actual module ID to identify this module.
 */
export const POLLY_POLL_MODULE_ID = '3985885e-fb3e-4dd6-929d-70bbf39dd1d2';


/**
 * Whether to use drop shadows in the SVG posted to Polly.
 * 
 * The current PDF generator used by Label A does not seem to
 * support drop shadows when rendering SVG images. If this
 * setting is false, an alternative method for displaying shadows
 * using transparent rectangles will be used.
 */
export const POLLY_SVG_SHADOWS = false;
