/**
 * Pattern Explorer lazy loader module.
 * 
 * Starts to render an empty page with a progress indicator while
 * further modules are imported dynamically. Note that the lazily
 * imported modules are bundled into a separate chunk by webpack,
 * so that the browser will not appear frozen to the end user while
 * the rest of the application is loading.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2022.
 */
import React, { Suspense } from 'react';
import { Splash } from "./init";

const DataLoader = React.lazy(() => import(/*
  webpackChunkName: "explorer-app",
  webpackPrefetch: true
*/ './DataLoader'));


/*
  Disable context-menu
  Note: this should disable contextmenus regardless of the type of
  event (mouse or touch) triggering them.
  Note: I don't really know where to put this code. Including it
  here so that it runs upon import seems straightforward enough,
  but it feels iffy.
*/
window.oncontextmenu = (event: Event) => {
  event.preventDefault();
};


const LazyLoader = () => (
  <Suspense fallback={<Splash message="◈◇◇" />}>
    <DataLoader />
  </Suspense>
);

export default LazyLoader;
