/**
 * Pattern Explorer full screen module.
 * 
 * Copyright (C) Sander Voerman <sander@savoerman.nl>, 2021-2023.
 */

import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import fscreen from 'fscreen';


const FullscreenContext = createContext(false);


export const FullscreenProvider = ({ children }: {
  children: ReactNode,
}) => {
  const [isFullscreen, setFullscreen] = useState(false);

  const handler = () => {
    setFullscreen(fscreen.fullscreenElement !== null);
  };

  useEffect(() => {
    fscreen.addEventListener('fullscreenchange', handler);
    return () => {
      fscreen.removeEventListener('fullscreenchange', handler);
    };
  }, []);

  return (
    <FullscreenContext.Provider value={isFullscreen}>
      {children}
    </FullscreenContext.Provider>
  );
};


export const useFullscreen = () => useContext(FullscreenContext);


export const enterFullscreen = () => {
  fscreen.requestFullscreen(document.documentElement);
};


export const exitFullscreen = () => {
  fscreen.exitFullscreen();
};
